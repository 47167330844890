.crop {
  overflow: hidden;
}

.crop img {
  width: 500px;
  height: 400px;
  object-fit: cover;
}

#btn-back-to-top {
  position: fixed;
  bottom: 13%;
  right: 10%;
  display: none;
  z-index: 1000;
  animation: fade-in 500ms;
}

@media (max-width: 600px) {
  .remove-img-in-responsive {
    display: none;
  }
}

//***ANIMATIONS***

//FADES
.fade-in-style {
  animation: fade-in 2s;
  animation-delay: 200ms;
  animation-fill-mode: backwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes reveal {
  from {
    opacity: 0;
    clip-path: inset(45% 20% 45% 20%);
  }
  to {
    opacity: 1;
    clip-path: inset(0% 0% 0% 0%);
  }
}

//LEFT, RIGHT... slide ins

@keyframes slide-in-left-full {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes slide-in-right-full {
  from {
    transform: translateX(200%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

.left-slide-full {
  animation: slide-in-left-full 1000ms;
  animation-fill-mode: backwards;
  animation-delay: 500ms;
}
.right-slide-full {
  animation: slide-in-right-full 1000ms;
  animation-fill-mode: backwards;
  animation-delay: 500ms;
}

@keyframes slide-in-left {
  from {
    transform: translateX(-60%);
    opacity: 0.2;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  from {
    transform: translateX(60%);
    opacity: 0.2;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

.left-slide {
  animation: slide-in-left 500ms;
  animation-fill-mode: backwards;
  animation-delay: 300ms;
}
.right-slide {
  animation: slide-in-right 500ms;
  animation-fill-mode: backwards;
  animation-delay: 300ms;
}

//PRINTER ONLY
@media print {
  // all the Print styles
  a.btn,
  button .btn,
  header,
  footer,
  .post-tag,
  .wp-block-embed,
  .post-badge,
  .comments-area,
  button.btn,
  iframe,
  #navbar,
  img {
    display: none;
  }
}

//BOOTSTRAP CUSTOM VALUES

.bi {
  vertical-align: text-bottom;
}

//***THEMES***

// [data-bs-theme="blue"] {
//     --bs-body-color: var(--bs-white);
//     --bs-body-color-rgb: #{to-rgb($white)};
//     --bs-body-bg: var(--bs-blue);
//     --bs-body-bg-rgb: #{to-rgb($blue)};
//     --bs-tertiary-bg: #{$blue-600};

//     .dropdown-menu {
//       --bs-dropdown-bg: #{mix($blue-500, $blue-600)};
//       --bs-dropdown-link-active-bg: #{$blue-700};
//     }

//     .btn-secondary {
//       --bs-btn-bg: #{mix($gray-600, $blue-400, .5)};
//       --bs-btn-border-color: #{rgba($white, .25)};
//       --bs-btn-hover-bg: #{darken(mix($gray-600, $blue-400, .5), 5%)};
//       --bs-btn-hover-border-color: #{rgba($white, .25)};
//       --bs-btn-active-bg: #{darken(mix($gray-600, $blue-400, .5), 10%)};
//       --bs-btn-active-border-color: #{rgba($white, .5)};
//       --bs-btn-focus-border-color: #{rgba($white, .5)};
//       --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .2);
//     }
//   }

// MOBILE PHONE GLOBAL STYLES
@media only screen and (max-width: 992px) {
  .carousel {
    mask-image: 
              linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0)),
              linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0));
  -webkit-mask-image: 
                      linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0)),
                      linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0));
  }
  .carousel img {
    object-fit: cover;
    max-width: 10px;
  }
  .carousel-item {
    height: 25rem;
  }
}

$primary: #00ffc7;

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
