/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}
/* rtl:end:ignore */


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  /* rtl:remove */
  letter-spacing: -.05rem;
}

/* Invert text colors based on theme */
[data-bs-theme="dark"] .carousel-caption {
  color: #fff !important;
}
[data-bs-theme="light"] .carousel-caption {
  color: #000 !important;
}

[data-bs-theme="dark"] .carousel-control-next-icon {
  filter: invert(0) grayscale(100) !important;
}
[data-bs-theme="dark"] .carousel-control-prev-icon {
  filter: invert(0) grayscale(100) !important;
}

[data-bs-theme="light"] .carousel-control-prev-icon {
  filter: invert(1) grayscale(100) !important;
}

[data-bs-theme="light"] .carousel-control-next-icon {
  filter: invert(1) grayscale(100) !important;
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target] {
  background-color: #fff !important;
}

[data-bs-theme="light"] .carousel .carousel-indicators [data-bs-target] {
  background-color: #000000 !important;
}

.carousel {
  mask-image: 
              linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0)),
              linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
  -webkit-mask-image: 
                      linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0)),
                      linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
}

.carousel img {
  object-fit: cover;
  /* Add the blur effect */
  filter: blur(3px);
  -webkit-filter: blur(3px);
}



/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}
