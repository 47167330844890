.wp-block-embed__wrapper {
  iframe {
    display: block;
    margin: 0 auto;
    border-radius: 10px; // or 100%
    box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
      inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
    max-width: 100%;
    max-height: 100%;
  }
}

.wp-element-caption {
  color: rgb(134, 135, 135);
}

.kk-star-ratings {
  display: none;
}

.wp-post-title{
  max-width: 80%;
  margin: 0 auto;
}
// .excerpt-limit{
//   -webkit-box-orient: vertical;
//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   line-clamp: 2;
//   overflow: hidden;
//   max-width: 720px;
//   margin-left: auto !important;
//   margin-right: auto !important;
// }
.categorie-description {
  height: 120px;
  width: 500px;
  max-width: 520px;
  max-height: 620px;
  overflow-y: auto;
  margin-left: auto !important;
  margin-right: auto !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.excerpt-limit {
  height: 120px;
  width: 500px;
  max-width: 520px;
  max-height: 220px;
  overflow-y: hidden;
  margin-left: 26% !important;
  margin-right: 26% !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.post-thumbnail {
  height: 110px;
  width: 200px;
  overflow: hidden;
  border-radius: 10px;
  float: left;
  margin-left: 25px;
}

.post-date {
  margin-right: 5%;
}

.post-text-wrapper {
  max-width: 620px;
  margin-left: auto !important;
  margin-right: auto !important;
}

// .comment-input-email input, .comment-input-name input, .comment-input-content textarea{
// box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
// border-radius: 5px;
// border-style: solid;
// @import "./globalVariables";
// border-color: $primary;
// }

// .comment-respond{

// }

.post-category {
  float: right;
  padding-right: 2%;
  overflow-y: auto;
  width: 200px;
  height: 140px;
}

.child-category {
  float: right;
  width: auto;
  height: auto;
  padding-right: 2px;
}
.post-read-time {
  padding-top: 10px;
}

.jump-to-recipe-button {
  float: left;
  padding-left: 8px;
}
.bi {
  display: inline-block;
}

.post-tag {
  float: right;
  padding-right: 0%;
  padding-left: 2%;
  width: 200px;
  height: 140px;
}

.child-tag {
  float: right;
  width: auto;
  height: auto;
  padding-right: 2px;
}

#autocomplete-result {
  position: absolute;
  z-index: 999;
  max-height: 190px;
  overflow-y: auto;
}
.post-search-bar {
  position: relative;
}

.filter-list-group {
  overflow: auto;
  max-height: 300px;
}
// MOBILE ONLY

@media only screen and (max-width: 992px) {
  .jump-to-recipe-button {
    width: 100%;
    padding-bottom: 5px;
    padding-left: 0px;
  }

  .categorie-description {
    height: auto;
    width: auto;
    max-width: auto;
    max-height: auto;
    margin-left: auto!important;
    margin-right: auto !important;
    -webkit-box-orient: horizontal;
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }

  .excerpt-limit {
    height: auto;
    width: auto;
    max-width: auto;
    max-height: auto;
    margin-left: 3% !important;
    margin-right: 3% !important;
    -webkit-box-orient: horizontal;
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }

  .post-thumbnail {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: auto;
    max-height: 250px;
    overflow: hidden;
    border-radius: 0px;
    float: none;
  }
  .post-category {
    display: none;
    float: right;
    padding-right: 0%;
    padding-left: 0%;
    max-height: 100px;
    overflow: none;
    width: auto;
    height: auto;
    max-width: 100px;
    max-height: 110px;
  }
  .child-category {
    float: right;
    width: auto;
    height: auto;
    padding-right: 2px;
  }
  .post-date {
    margin-right: 0%;
  }
  .post-badge {
    position: static;
    float: none;
  }
  .post-tag {
    display: none;
  }
  .post-search-bar {
    text-align: center;
  }
  #autocomplete-result {
    position: relative;
  }
  .filter-list-group {
    overflow-y: auto;
    max-height: 125px;
  }
}
