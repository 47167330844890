.comment-body,
.comment-respond {
  padding: 1.5em;
  margin: 0 0 1.5em;
  overflow: auto;
  border: 1px solid;
  border-radius: 4px;
}

.comment-header,
.comment-submit {
  overflow: auto;
  padding-bottom: 10px;
}

.comment-submit button {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    color: #000000;
    @import "./globalVariables";
    background-color: $primary;
}

.comment-submit button:disabled {
  opacity: 0.8;
  pointer-events: none;
}

.comment-avatar,
.comment-meta {
  float: left;
}

.comment-meta {
  margin-left: 0.5em;
}

.comment-reply-link a,
.reply-title small {
  float: right;
  font-weight: 400;
  color: #212529;
  text-align: center;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  color: #fff;
  background-color: #85878a;
}

.comment-children {
  padding-left: 5%;
}

.comment-respond fieldset {
  display: inline-block;
}

fieldset input {
  float: right;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #495057;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.comment-respond textarea {
  display: block;
  width: 100%;
  max-width: 100%;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #495057;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  
}

.comment-list,
.comment-children {
  margin: 0;
  list-style: none;
}

.comment-list {
  padding-inline-start: 0px;
}

.comments-area .loader {
  display: inline-block;
  vertical-align: middle;
  border: 2px solid transparent;
  border-bottom: 2px solid;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  margin: 0.2em auto;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}